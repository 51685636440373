$(document).on("turbolinks:load", () => {
	$('.slider-indicator').click(function(){
		if(!$(this).hasClass('active')){
			$('.slider-indicator').removeClass('active');
			$(this).addClass('active');

			$('.slider-image').removeClass('active')
			$('.slider-image[data-image=' + $(this).data('indicator') +']').addClass('active');
		}
	});

	function getNext(i){
		return (i + 1) % $('.slider-image').length;
	}

	$('.slider-image').click(function(){
		let nextImage = getNext(parseInt($(this).data('image')));
		$('.slider-image').removeClass('active');
		$('.slider-image[data-image=' + nextImage + ']').addClass('active');

		$('.slider-indicator').removeClass('active');
		$('.slider-indicator[data-indicator=' + nextImage +']').addClass('active');
	});
}); 