$(document).on("turbolinks:load", () => {

	function playVideo(e, video) {
		if (video.paused) {
      e.preventDefault();
      video.setAttribute("controls","controls");
      video.play();
    } else {
      e.preventDefault();
      video.pause();
    }
	}

	$('.video-overlay').click(function(e) {
		var video = $('video', $(this).parent()).get(0);
		$(this).remove();
		playVideo(e, video)
	})

	$('video').click(function (e) {
    var video = $(this).get(0);
    playVideo(e, video)
  });
  
}); 